import React from "react";

import Layout from "../../components/layout";
import { Paper, Typography, Grid, styled } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import ThumbnailImage from "../../components/thumbnail";
import YoutubeEmbed from "../../components/youtube";

const Root = styled(`div`)(({ theme }) => ({
  backgroundColor: "#fff",
  backgroundImage:
    "linear-gradient(45deg, #D0D0D0 25%, transparent 25%), linear-gradient(-45deg, #D0D0D0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D0D0D0 75%), linear-gradient(-45deg, transparent 75%, #D0D0D0 75%)",
  backgroundSize: "250px 250px",
  backgroundPosition: "0 0, 0 125px, 125px -125px, -125px 0px",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.86)",
  padding: theme.spacing(2),
}));

const query = graphql`
  query SuperXtremeKartRacing2Query {
    image1: file(relativePath: { eq: "projects/sxkr2/kart1.png" }) {
      ...projectThumbnailImage
    }

    image2: file(relativePath: { eq: "projects/sxkr2/kart2.png" }) {
      ...projectThumbnailImage
    }

    image3: file(relativePath: { eq: "projects/sxkr2/kart3.png" }) {
      ...projectThumbnailImage
    }
  }
`;

const Sxkr2Page: React.FC = () => {
  const projectName = `Super Xtreme Kart Racing 2`;
  const data = useStaticQuery(query);

  return (
    <Root>
      <Layout pageTitle={projectName}>
        <StyledPaper>
          <Typography variant="h4" component="h1">
            {projectName}
          </Typography>
          <Typography variant="body1" component="p">
            Super Xtreme Kart Racing 2 is a Super Mario Kart style racing game
            that incorporates humor and style with cartoon-ish graphics,
            challenging A.I. and physics. Super Xtreme Kart Racing 2 is an
            enjoyable game that brings together humor and racing. Compete as one
            of the eight amusing racers on fun, exciting, and challenging
            racetracks. Why the 2 you ask? Because this game is that Xtreme!
          </Typography>
          <br />
          <Typography variant="body1" component="p">
            This game project was created during my sophomore year. My team
            consisted of 4 total members. One producer, one technical director,
            one designer, and one product manager. I was the technical director
            and the lead graphics programmer for this project. My involvement in
            this project consisted of making the graphics architecture,
            debugging for hard bugs to find, increasing the performance of our
            game, and integrating game engines.
          </Typography>
          <Typography variant="h5" component="h2" sx={{ marginTop: 3 }}>
            Gold Release
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ThumbnailImage file={data.image1} alt="Screenshot 1" />
            </Grid>
            <Grid item xs={4}>
              <ThumbnailImage file={data.image2} alt="Screenshot 2" />
            </Grid>
            <Grid item xs={4}>
              <ThumbnailImage file={data.image3} alt="Screenshot 3" />
            </Grid>
          </Grid>
          <br />
          <YoutubeEmbed id="6BdEw96Dhnk" />
        </StyledPaper>
      </Layout>
    </Root>
  );
};

export default Sxkr2Page;
